<template>
  <video class="videoBox" :controls="!!url" :id="id"></video>
</template>

<script>
import mpegts from 'mpegts.js'
export default {
  name: 'mpegtsCom',
  data() {
    return {
      player: null,
      url: '',
    }
  },
  created() {
    this.destroyPlayer()
  },
  mounted() {},
  beforeDestroy() {
    this.destroyPlayer()
  },
  props: {
    id: {
      type: String,
      default: 'videoID',
    },
  },
  methods: {
    // 初始化
    initPlayer() {
      if (mpegts.getFeatureList().mseLivePlayback) {
        console.log('加载')
        this.player = mpegts.createPlayer({
          type: 'flv', // could also be mpegts, m2ts, flv mse
          isLive: true,
          url: this.url,
          hasAudio: false,
          enableStashBuffer: false,
          liveBufferLatencyChasing: true,
          liveBufferLatencyChasingOnPaused: true,
        })
        this.player.attachMediaElement(document.getElementById(this.id))
        this.player.load()
        this.player.play()
        this.listeningPlayer()
      }
    },
    // 替换视频
    switchURLPlayer(url) {
      this.url = url
      this.destroyPlayer()
      this.initPlayer()
    },
    // 销毁视频
    destroyPlayer() {
      if (!!this.player) {
        console.log('销毁')
        try {
          this.player.pause()
          this.player.unload()
          this.player.detachMediaElement()
          this.player.destroy()
          this.player = null
        } catch (e) {
          console.log('出错', e)
        } finally {
          // this.mpegts = null
        }
      }
    },
    // 监听
    listeningPlayer() {
      this.player.on(
        mpegts.Events.ERROR,
        (ErrorTypes, ErrorDetails, ErrorInfo) => {
          // 这里处理错误
          console.log('err=>', ErrorTypes, ErrorDetails, ErrorInfo)
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.videoBox {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
}
</style>
